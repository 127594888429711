import React, { useContext, useEffect, useRef, useState } from 'react'
import {QueryReferrelsHistories} from "../../apollo/queries";
import { gql, useApolloClient } from '@apollo/client';
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import Lottie from 'lottie-react';
import { FiGift } from "react-icons/fi";
import { MdErrorOutline } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import moment from 'moment';
import CheckReferalStatusCode from '../../utils/CheckReferalStatusCode'
import { toast } from 'react-toastify';
import UseCookie from '../../utils/cookies/useCookie';

const getNestCashTransactions = gql`${QueryReferrelsHistories}`;

export default function ReferralHistory(props) {

    const client = useApolloClient();
    const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
    const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
    const scrollDivRef = useRef(null);
    var [isLoading, setIsLoading] = useState(false);
    var [nestCashData, setNestCashData] = useState([]);
    var [totalCount, setTotalCount] = useState(0);
    var [pageNumber,setPageNumber] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);

    const { getCustomCookie } = UseCookie();

    // Refs to hold the latest values of pageNumber and isLoadingMore
    const pageNumberRef = useRef(pageNumber);
    const isLoadingMoreRef = useRef(loadingMore);

      // Update the refs whenever the state changes
    useEffect(() => {
        pageNumberRef.current = pageNumber;
    }, [pageNumber]);

    useEffect(() => {
        isLoadingMoreRef.current = loadingMore;
    }, [loadingMore]);

    useEffect(() => {
        const divElement = scrollDivRef.current;
        if (divElement) {
          divElement.addEventListener('scroll', handleScroll);
        }
        return () => {
          if (divElement) {
            divElement.removeEventListener('scroll', handleScroll);
          }
        };
      }, [loadingMore]);

    useEffect(() => {
        if(props?.isChecked){
          initialDataload()
        }
      },[props?.isChecked])

      const requestReferralHistoryData = async (requestBody) => {
        try{
          const { data: nestCashTransactionData } = await client.query({
              query: getNestCashTransactions,
              variables: requestBody,
              fetchPolicy: "network-only",
          });
          const newTransactions = nestCashTransactionData?.queryReferrelsHistories?.referrelsHistory || [];
          // Append new data to the existing list
        //   setNestCashData(newTransactions);
          setNestCashData((prevData) => [...prevData, ...newTransactions]);
          pageNumber = pageNumber + 1
          setPageNumber(pageNumber);
          totalCount = nestCashTransactionData?.queryReferrelsHistories?.totalCount
          setTotalCount(totalCount)
        }
        catch(error){
            console.log("🚀 ~ requestReferralHistoryData ~ error:", error)
            
        }
      }

      const initialDataload = async()=>{
        window.scrollTo(0, 0);
        pageNumber = 0
        setPageNumber(pageNumber)
        isLoading = true
        setIsLoading(isLoading)
        let requestBody = {  
            "limit": 10,
            skip: 10 * pageNumber,
          }
        try{
            await requestReferralHistoryData(requestBody)
            isLoading = false
            setIsLoading(isLoading)
        }
        catch(error){
            isLoading = false
            setIsLoading(isLoading)
        }
      }

      const loadMoreItems = async() => {
        //console.log("🚀 ~ loadMoreItems ~ ordersHistoryData?.queryAllOrders?.totalCount <= (10 * (pageNumber+1):", ordersHistoryData?.queryAllOrders?.totalCount , (10 * (pageNumberRef?.current)))
        if (isLoadingMoreRef?.current) return;
        if(totalCount <= (10 * (pageNumberRef?.current))) return;
        try{
          let requestBody = {
            "limit": 10 ,
            skip: 10 * pageNumberRef?.current,
          }
          await requestReferralHistoryData(requestBody)
        }
        catch(error){
        }
      };

      const handleScroll = () => {
        const divElement = scrollDivRef.current;
        if (
          divElement &&
          divElement.scrollTop + divElement.clientHeight >= divElement.scrollHeight - divElement.clientHeight * 0.2 &&
          !loadingMore
        ) {
            loadMoreItems();
        }
      };

      const handleCopy = async() => {
        let loginInfo = await getCustomCookie('loginInfo')
        let text = `Hi! I'm inviting you to join NativeNest.\n\nExperience an extensive selection of authentic Malayalee flavors of Kerala, delivered straight to your kitchen.\n\nSign up with my referral code <${loginInfo?.phone?.split("+91")[1]}> to get an exclusive discount on your first order!.\n\nDiscover endless offers and features designed just for you!\n\nDownload NativeNest now: https://nativenest.in/apps`
        if (navigator.clipboard) {
            // Modern Clipboard API
            navigator.clipboard.writeText(text).then(() => {
                toast.success(`Invitation copied to clipboard`);
            }).catch(err => {
                toast.error('Failed to copy: ', err.message);
            });
        } else {
            // Fallback for older browsers
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy');
                toast.success(`Invitation copied to clipboard`);
            } catch (err) {
                toast.error('Failed to copy: ', err.message);
            }
            document.body.removeChild(textarea);
        }
    };

      const formatOrderDate = (utcDate) => {
        const localDate = moment.utc(utcDate).local();
      
        // Get day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd, ...)
        const dayOfMonth = localDate.format('Do');
      
        // Format date as '30th Apr 2024'
        const formattedDate = localDate.format('Do MMM YYYY');
      
        // Format time as '03:00 pm'
        const formattedTime = localDate.format('hh:mm a');
      
        // Combine formatted date and time
        const formattedDateTime = `${formattedDate} at ${formattedTime}`;
      
        //console.log("🚀 ~ formatOrderDate ~ formattedDateTime:", formattedDateTime)
        return formattedDateTime;
      };

  return (
    <>
        <div ref={scrollDivRef} className='mt-2p pb-2 overflow-y-scroll hide-scroll-bar max-h-[90vh]'>
            {isLoading ?
                <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>:
                <div className='flex flex-col'>
                    <div 
                    onClick={handleCopy}
                    className={` shadow relative flex flex-row items-center justify-between cursor-pointer transition duration-100 active:scale-95 mb-3 p-5 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor}  rounded-xl`} 
                        style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} >
                            <span className={`text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg font-semibold text-appThemeColorVeryDark`}>Copy Invite Link</span>
                            <FaCopy className={`text-${colors?.cartIconBadge}`} size={32} />
                            {/* <div className='flex flex-col gap-2'>
                                <span className={`text-lg sm:text-lg md:text-2xl lg:text-2xl xl:text-3xl font-bold text-${colors?.heading}`}>₹{nestCashData?.balance}</span>
                                <span className={`text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg font-normal text-appThemeColorVeryDark`}>Available Balance</span>
                            </div>
                            <Lottie className="w-32" animationData={require('../../assets/NestCash.json')} /> */}
                    </div>
                    <span className={`p-1 text-sm md:text-md font-semibold text-black`}>Your Referrals</span>
                    <div className='flex flex-col gap-2'>
                    {
                        nestCashData?.length>0?
                        <>
                            {
                                nestCashData?.map(data=>(
                                    <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`p-4 rounded-lg flex flex-row gap-2 items-center w-full bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor}`}>
                                        {data?.percentageApplied?
                                        <span className={`font-semibold text-sm md:text-md text-appThemeColorVeryDark`}>{data?.percentageApplied}% Off</span>:
                                        data?.status == 0?
                                        <MdErrorOutline style={{color:colors?.fail}} size={32} />:
                                        <FiGift className={`text-${colors?.cartIconBadge}`} size={32} />}
                                        <div className='flex flex-row justify-between items-center w-full'>
                                            <div className='flex flex-col items-start gap-1'>
                                                <span className={`font-semibold text-sm md:text-md text-black`}>{data?.refereeName || 'Guest'}</span>
                                                <span className={`font-normal text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs text-textlightGrey`}>{(data?.refereePhone)}</span>
                                            </div>
                                            <div className='flex flex-col gap-1 items-end justify-end'>
                                                <span className={`font-semibold text-sm sm:text-sm md:text-md ${data?.status == 0?'text-black':'text-appThemeColorVeryDark'}`}>{CheckReferalStatusCode(data?.status)}</span>
                                                <span className={`font-normal text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs text-textlightGrey`}>{formatOrderDate(data?.registeredDate).split("at")[0]}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </>:
                        <div className='h-[50vh] flex flex-col items-center justify-center text-center'>
                            <span className={`text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg font-normal text-black`}>No Referrals Yet</span>  
                        </div>
                    }
                    </div>
                </div>
            }
        </div>
    </>
  )
}
