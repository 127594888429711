export const en = {
  add: 'Add to Cart',
  accountSettings: 'Account Settings',
  addToCart: 'Add to Cart',
  inviteFriends:'Invite Friends',
  orderHistory:"Order History",
  personalInformation:"Personal Information",
  appearance:"Appearance",
  lngAndCountry:"Language and Country",
  pushNotification:"Push Notifications",
  paymentMethods:"Payment methods",
  support:"Support",
  legal:"Legal",
  faq:"FAQ",
  privacyPolicy:"Privacy Policy",
  termsConditions:"Terms and Conditions",
  logOut:"Logout",
  placeOrder:"Place Order",
  ItemDetails:"Item Details",
  ProductDescription:"Product Decription",
  SimilarProducts:"Similar Products",
  ViewCart:"View Cart",
  CheckOut:"Checkout",
  Address:"Address",
  PaymentMethod:"Payment Method",
  applyCoupon:"Apply Coupon",
  Coupons:"Coupons",
  AddCouponCode:"Add Coupon Code",
  GooglePay:"Google Pay",
  ConfirmAndPay:"Confirm and Pay",
  AddCouponCode1:"",
  OrderSummary:"Order Summary",
  OrderNO:"Order no",
  DeliveryCharges:"Delivery Charge",
  HandlingCharge:"Handling Charge",
  Tax:"Tax",
  GrandTotal:"Grand Total",
  ItemsTotal:"Total Items Price",
  SubTotal:"Sub Total",
  PrivacyDisclaimer:"By placing your order you agree to our Terms and Conditions and confirm that you have read our Policy.",
  TrackYourOrder:"Track Your Order",
  OrderStatus:"Status",
  OrderConfirmed:"Order Confirmed",
  OrderConfirmedMsg1:"Your order has been confirmed.",
  OrderConfirmedMsg2:"Will be delivered soon",
  OrderFailed:"Order Failed",
  OrderFailedMsg1:"Your order has been Failed.",
  OrderFailedMsg2:"try Again",
  OrderInitiated:"Order Initiated",
  OrderInititatedMsg1:"Your order has been initiated.",
  OrderInititatedMsg2:"Will be confirmed soon",
  BackToCart : "Back To cart",
  CreateAnAccount:"Create an account",
  LoginUsingMobileNumber:"Please Verify Mobile Number",
  LogIn:"Log In",
  signIn:"Sign In",
  signUp:"Sign Up",
  Skip:"Skip",
  WelcomeToNativeNest:"Welcome to NativeNest",
  SignInAccount:"And login to your account",
  MobileNumber:"Mobile Number",
  OTP:"OTP",
  RememberMe:"Remember Me",
  DontHaveAccount:"Don't have an account ?",
  AlreadyHaveAccount:"Already have an account ?",
  Welcome:"Welcome",
  CreateYourAccount:"Create Your Account",
  CreateAccount:"Create Account",
  send: 'Send',
  sending:'Sending...',
  loginToProceed:"Login to proceed",
  addAddress: 'Add new address',
  savedAddress : 'Your saved addresses',
  Nosavedaddresses : 'No saved addresses',
  cod: 'Cash on delivery',
  online: 'Online Payment',
  ordersHistory:'Orders history',
  subCategories:'Sub-Categories',
  theme:'Theme',
  light:'Light',
  dark:'Dark',
  systemDefault:'System default',
  ItemsDiscount:'Items Discount',
  TotalSavings:'Total Savings',
  MRP:'MRP',
  ItemsTotalPrice:'Items Total Price',
  SeeAll:"See All",
  AllProducts:"All Products",
  enterCompleteAddress:"Enter Complete Address",
  saveAddress : "Save Address",
  Offline:'You are Offline',
  OfflineMsg:'You are not connected to the internet. Please connect to the internet and try again',
  SelectUnit:'Select Unit',
  poinToNewLocation:'Point to New Location',
  poinToNewLocation:'Point to New Location',
  addAdditionalAddress:'Address - Add Additional Info',
  editAdditionalAddress:'Address - Edit Additional Info',
  addAddress:'Address - Add Address Info',
  gettingCurrentLocation:'Getting Current Location',
  getCurrentLocation:'Use Current Location',
  SearchLocation:'Search Location',
  pressAgain:'Press again to exit from NativeNest',
  NestCash:'Nest Cash',
  Wallet:'Wallet',
  Coupon:'Coupon',
  OffersApplied:'Offers Applied',
  failedToSave:'Failed To Save',
  // isLocationServicable:'',
  ChooseTheme:'Choose Theme',
  noItemsExistsWith: 'No items exists with',
  locationAccessMsg:"To deliver as quickly as possible, we would like your current location",
  submit: 'Submit',
  OutofStock:'Out of Stock',
  CancelOrder:'Cancel Order',
  RetryPayment:'Retry Payment',
  UpdateOrder:'Update Order',
  UpdateCustomerDetails:'Update Profile Details',






  


  title0: 'Selected Language',
  subtitle0: 'English',
  description0:
    'Select any language of your choice to change the content of the app to your required language.',
  title1: 'Tasty',
  subtitle1: 'BreakFast',
  description1:
    'Breakfast is everything. The beginning, the first thing. It is the mouthful that is the commitment to a new day, a continuing life.',
  title2: 'Refreshing',
  subtitle2: 'Drinks',
  description2:
    'True silence is the rest of the mind, and is to the spirit what sleep is to the body, nourishment and refreshment.',
  title3: 'Delicous',
  subtitle3: 'Icecream',
  description3:
    'Age does not diminish the extreme disappointment of having a scoop of ice cream fall from the cone',
  getStarted: 'Get Started!',
  welcome:
    "Welcome to Enatega, sometimes all you need is love. But a little food now and then doesn't hurt.",
  loginBtn: 'Login',
  registerBtn: 'Register',
  name: 'Name',
  phone: 'Phone',
  email: 'Email',
  emailphone: 'Email or Phone',
  password: 'Password',
  deliveryAddress: 'Delivery Address',
  fullDeliveryAddress: 'Your full delivery address',
  deliveryDetails: 'Required details -e.g. floor/ building',
  myAddresses: 'My Addresses',
  editAddress: 'Edit Address',
  registerText: 'Or Register With',
  forgotPassword: 'Forgot Password?',
  loginText: 'Or Login With',
  deliveryLocation:
    'Turn on location so we could send you endless taste of delicious food.',
  locationBtn: 'Turn on Location',
  locationPermissionDenied: 'Permission to access location was denied',
  cameraRollPermissionDenied: 'Permission to access Camera Roll was denied',
  locationOff: 'Turn on location and try again',
  titleLanguage: 'Change Language',
  titleMenu: 'Menu',
  titleOrders: 'My Orders',
  titleReorder: 'Previous Order',
  titleNotifications: 'Notifications',
  titleReviews: 'Reviews',
  titleProfile: 'Profile',
  titleSettings: 'Settings',
  titleHelp: 'Help',
  titleChat: 'Chat',
  titleLogout: 'Logout',
  titleCart: 'My Cart',
  titlePayment: 'Payment',
  totalOrderAmount: 'Total Order Amount',
  ManageReferrals:'Manage Referrals',
  reOrder: 'Reorder',
  ReOrder: 'Re-Order',
  previous: 'Previous Order',
  unReadNotifications: 'No unread notifications',
  upload: 'Upload',
  saveBtn: 'Save',
  saveContBtn: 'SAVE AND CONTINUE',
  emailUs: 'Email us at',
  question1: 'Where do we find the food?',
  question2: 'How do we contact?',
  question3: 'How can I pay the delivery person?',
  question4: 'Is the service available in my city?',
  answer1:
    'You can find the food at your nearest store without paying anything to customer service.Our charges are extremely low as compared to others.',
  answer2: 'You can contact us through our email, phone number or our website.',
  answer3:
    'You can pay the delivery person in person or pay online as well through credit or debit card.',
  answer4:
    'Currently this service is available in cities Islamabad and Karachi you can contact us to avail this service in your city.',

  quantity: 'Quantity',
  size: 'Size',
  orderNow: 'Order Now',
  addToCartMessage: 'Added to cart',
  emptyCart: 'No items in cart',
  itemTotal: 'Item Total',
  delvieryCharges: 'Delivery Charges',
  total: 'Total',
  contactInfo: 'Contact Info',
  deliveryAddressmessage: 'Set delivery address',
  proceedCheckout: 'Proceed to Checkout',
  paymentText: 'How do you wish to pay?',
  checkout: 'Checkout',
  creditCart: 'Credit Card/Debit Card',
  paypal: 'Paypal',
  thankYou: 'Thank You!',
  orderConfirmed: 'Your Order is confirmed',
  orderId: 'Your Order Id',
  orderAmount: 'Your Order Amount',
  orderDetail: 'Order Detail',
  paymentMethod: 'Payment Method',
  ChnagePaymentMethod: 'Change Payment Method',
  trackOrder: 'Track Order',
  backToMenu: 'Back To Menu',
  foodItem: 'Food item',
  deliveredTo: 'Delivered to',
  writeAReview: 'Write a Review',
  orderReceived: 'Order Received',
  orderPicked: 'Your order is on its way',
  orderDelivered: 'Delivered',
  completed: 'Completed',
  cancelled: 'Cancelled',
  orderPreparing: 'Your order is being prepared',
  delivered: 'Delivered',
  rateAndReview: 'Rate and Review',
  reviewPlaceholder: 'More detailed reviews get more visibility...',
  noWorriesText: 'No worries, let us help you out!',
  yourEmail: 'Your Email',

  apply: 'Apply',
  checkEmail: 'Check your email for reset password link',
  languageText: 'Please select your required language',
  countryCodePickerTranslation: 'eng',
  countryCodeSelect: 'Select Country Code',
  paymentNotSupported: 'This payment method does not support this Currency',
  loginOrCreateAccount: 'Login/Create Account',
  unReadReviews: 'No Reviews Yet!',
  unReadOrders: 'No Orders Yet!',
  error: 'Error',
  noMoreItems: 'No more items in stock',
  hungry: 'Hungry',
  emptyCartBtn: 'Back to Food',
  emptyFavBtn: "Let's find some favourites",
  subTotal: 'SubTotal',
  deliveryFee: 'Delivery Fee',
  haveVoucher: 'Do you have a voucher ?',
  remove: 'Remove',
  change: 'Change',
  condition1: 'By completing this order, I agree to all Terms & Conditions.',
  condition2:
    'I agree and I demand that you execute the ordered service before the end of the revocation period. I am aware that after complete fulfillment of the service I lose my right of rescission.',
  orderBtn: 'Place order',
  coupanApply: 'Coupon discount applied',
  coupanFailed: 'Coupon Unavailable',
  invalidCoupan: 'Invalid Coupon',
  validateItems: 'Add items to cart before checkout',
  validateDelivery: 'Set delivery address before checkout',
  titleFavourite: 'Favourites',
  titleEmptyFav: 'No Favourites Saved',
  emptyFavDesc:
    "You'll see all your favourites here, to make ordering even faster.",
  justLook: 'Just look for the',
  taxFee: 'Tax Charges',
  viewCart: 'VIEW YOUR CART',
  viewcart:'View Cart',
  featured: 'Featured',
  allRestaurant: 'All Restaurants',
  titleDeliveryDetails: 'Delivery Details',
  titlePickUpDetails: 'Pickup Details',
  tip: 'Tip',
  reviewRegardingOrder: 'Review regarding your order?',
  anySuggestion: 'Any suggestion?',
  newUser: 'New User? Create an account',
  signUporSignIn: 'Sign Up or Sign In',
  or: 'or',
  termCondition1: 'By continue, you agree to our',
  temrConditions: 'Term & Conditions',
  privacyPolicy: 'Privacy Policy',
  and: 'and',
  whatsYourEmail: "What's your Email?",
  checkAccount: "We'll check if you've an account",
  signInWithEmail: 'Sign in with your email',
  continueBtn: 'Continue',
  emailErr1: 'Email is required',
  emailErr2: 'Invalid Email!',
  passErr1: 'Password is required',
  passErr2:
    'Invalid Password. Password must contain 1 capital letter, 1 small letter, 1 number.',
  letsGetStarted: "Let's get started!",
  createAccount: 'Create your Enatega Account',
  firstnameErr1: 'Firstname is required!',
  firstnameErr2: 'Invalid First Name!',
  lastnameErr1: 'Lastname is required!',
  lastnameErr2: 'Invalid Last Name!',
  mobileErr1: 'Phone number is required!',
  mobileErr2: 'Invalid Phone number',
  verifyEmail: 'Verify your email?',
  otpSentToEmail: 'Please enter OTP we sent to your email.',
  verifyPhone: 'Verify your phone number?',
  otpSentToPhone: 'Please enter OTP we sent to your phone number.',
  wrongOtp: 'Wrong otp. Please try again!',
  resendBtn: 'Resend',
  enterYourEmail:
    "Enter your email and we'll send you an OTP to reset your password.",
  backToLogin: 'Back to login',
  setYourPassword: 'Set your Password',
  enterPass: 'Kindly enter your password',
  confirmPassRequired: 'Confirm password is required!',
  yourPhoneNumber: "What's your Phone Number?",
  secureAccountWithPhone: 'We need this to secure your account',
  Home: 'Home',
  Work: 'Work',
  Other: 'Other',
  addLabel: 'Add Label',
  profileDetail: 'Personal details',
  update: 'Update',
  mobile: 'Mobile',
  addPhone: 'Add Phone',
  verified: 'Verified',
  verify: 'Verify',
  unverified: 'Unverified',
  changePassword: 'Change password',
  currentPassword: 'Current password',
  newPassword: 'New Password',
  updatePassword: 'Password Updated',
  searchAddress: 'Search Address',
  addToCart: 'Add To Cart',
  loading: 'Loading...',
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  ASSIGNED: 'ASSIGNED',
  PICKED: 'PICKED',
  DELIVERED: 'DELIVERED',
  COMPLETED: 'COMPLETED',
  pendingOrder: 'Your order is still pending.',
  acceptedOrder: 'Restaurant is preparing Food.',
  assignedOrder: 'Assigned a rider.',
  pickedOrder: 'Rider is on the way.',
  deliveredOrder: 'Order is delivered.',
  completedOrder: 'Order is completed.',
  PENDINGStatusMessage: 'Waiting for restaurant to accept',
  ACCEPTEDStatusMessage: 'Food is being prepared',
  ASSIGNEDStatusMessage: 'Assigned a rider',
  PICKEDStatusMessage: 'Rider on his way',
  DELIVEREDStatusMessage: 'Thank you!',
  CANCELLEDStatusMessage: "We're sorry, Your order was cancelled.",
  yourOrderAt: 'Your order at',
  delivery: 'Delivery',
  Min: 'Minute',
  min: 'Min',
  Closed: 'Closed',
  PenddingText:
    "We're asking the restaurant how long it will take to deliver your food.",
  PenddingText1: 'The restaurant rider will be at your place around.',
  OrderFrom: 'Your order from:',
  OrderNo: 'Your order no:',
  ActiveOrder: 'Active Orders',
  PastOrder: 'Past Orders',
  ContinueWithGoogle: 'Continue with Google',
  ContinueWithEmail: 'Continue with Email',
  Cancel: 'Cancel',
  DeleteAccount: 'Delete Account',
  DeleteConfirmation: 'Are you Sure you want to delete Account?',
  chatWithRider: 'Chat with rider',
  yourOrderIs: 'Your order is',
  noItemsExists: 'No items exists',
  pickUpAddress: 'PickUp Address',
  selectedLocation: 'Selected Location',
  noReviewYet: 'There are no reviews yett.',
  Openingtimes: 'Opening times',
  ClosedAllDay: 'Closed all day',
  About: 'About',
  Reviews: 'Reviews',
  emptyHere: "It's empty here.",
  emptyHereDecs1: "You haven't saved any address yet.",
  emptyHereDecs2: "Click Add New Address to get started.",
  changeAddress: 'Change Address',
  pickUp: 'Pick Up',
  Custom: 'Custom',
  save: 'Save',
  SelectPickupDT: 'Select Pickup date and time',
  SelectDeliveryDT:  'Select Delivery date and time',
  viewLess: 'View Less',
  viewAll: 'View All',
  currentLocation: 'Current Location',
  useCurrentLocation: 'use current location',
  locationPermissionMessage: 'Allow NativeNest to access your location.',
  // locationPermissionMessage: 'Tap on this message to open Settings then allow app to use location from permissions.',
  enategaUseYourLocationMessage: 'NativeNest uses your location to show the servicable groceries !',
  selectAnotherLocation: 'Select another location',
  readyForService: 'Ready to serve',
  deliveringOrder: 'Delivering your order to',
  setLocation: 'Set Location',
  cartIsEmpty: 'Cart is empty',
  favouritelistUpdated: 'Favourite list updated',
  minAmount: 'The minimum amount of ',
  forYourOrder: ' for your order has not been reached.',
  setPaymentMethod: 'Set payment method before checkout',
  numberVerificationAlert: 'Phone number is not verified. Kindly verify phone number.',
  numberVerified: 'Phone number has been verified successfully!.',
  numberAddedAlert: 'Phone number has been added successfully!.',
  itemNotAvailable: 'One or more item is not available',
  accountDeactivated: 'Account Deactivated',
  addressUpdated: 'Address Updated',
  errorOccured: 'An error occured. Please try again ',
  otpForResetPassword: 'OTP for reset password has been sent to your email.',
  passwordResetSuccessfully: 'Password Reset successfully!',
  passwordMustMatch: 'Password must match with confirm password',
  emailAssociatedWith: 'Your email is associated with ',
  continueWith: '. Kindly continue with ',
  mailCheckingError: 'Error while checking email. Try again later!',
  errorInLoginError: 'Error in login Error',
  errorWhileLogging: 'Error while logging in. Please try again later',
  accountCreated: 'Your account has been created.',
  invalidPassword: 'Invalid password',
  userInfoUpdated: "User's Info Updated",
  nameError: 'Name is required',
  notificationStatusUpdated: 'Notification Status Updated',
  notificationNotWork: 'Notification do not work on simulator',
  errorInProfile: 'Error in profile',
  invalidAmount: 'Invalid Amount',
  amountMustBe: 'Amount must be greater than 0',
  otherAmount: 'Other amount',
  addOtherAmount: 'Add Other amount',
  otpResentToEmail: 'OTP resent to your email.',
  search: 'Search',
  searchRestaurant: 'Search for restaurants',
  searchLocation: 'Search for area, street name...',
  searchGrocery: 'Search for groceries...',
  searchGroceryItems: 'Search Groceries',
  message: 'Message',
  aptFloor: 'Apt | Floor',
  confirmPassword: 'Confirm Password',
  specialInstructions: 'Special instructions',
  anySpecificPreferences: 'Any specific preferences?',
  optional: 'Optional',
  noMayo: 'E .g No mayo',
  mobileNumber: 'Mobile Number',
  firstNamePH: 'First name',
  lastNamePH: 'Last name',
  titleCustomize: 'Customize',
  titleMyVouchers: 'My Vouchers',
  titleProductPage: 'Product Page',
  titleDocs: 'Docs',
  titleBlog: 'Blog',
  titleAboutUs: 'About Us',
  paypalCheckout: 'Paypal Checkout',
  stripeCheckout: 'Stripe Checkout',
  titleTipping: 'Tipping',
  statusOrderPalced: 'Order palced',
  statusAccepted: 'Accepted',
  statusAssigned: 'Assigned',
  statusPicked: 'Picked',
  statusDelivered: 'Delivered',
  ///
  selectLocation: 'Select Location',
  ChooseYourLocation: 'Choose Your Location',
  EditAddress: 'Edit Address',
  EditLocation: 'Edit Location',
  selectDeliveryLocation: 'Select Delivery Location',
  selectAddress: 'Select Address',
  confirmLocation: 'Confirm Location',
  languageSetting: 'Language Setting',
  receiveSpecialOffers: 'Receive Special Offers',
  getUpdates: 'Get updates on your order status',
  turnOnDarkTheme: 'Turn on Dark Theme',
  selectLanguage: 'Select Language',
  Select: 'Select',
  DELETEACCOUNT: 'DELETE ACCOUNT',
  ETA: 'ETA',
  contactYourRider: 'Contact your Rider',
  selectCountry: 'Select Country',
  MON: 'MON',
  TUE: 'TUE',
  WED: 'WED',
  THU: 'THU',
  FRI: 'FRI',
  SAT: 'SAT',
  SUN: 'SUN',
  DeliveryAddressIsRequired: 'Delivery address is required',
  SelectVariation: 'Select Variation',
  SelectOne: 'Select one',
  Required: 'Required',
  howWasMeal: 'How was your meal?',
  howWasMealP: 'Was it nice or bad,Lets talk about it',
  RateYourOrder: 'Rate Your Order',
  yourExperience: 'Tell about your Experience',
  selectItems: 'Select Items',
  dontHaveAnyOrderYet: "You don't have any past order yet. Try one of our awesome restaurants and place your first order!",
  BrowseRESTAURANTS: 'Browse RESTAURANTS IN YOUR AREA',
  RateOrder: 'Rate Order',
  voucherCode: 'Enter your voucher code',
  networkError: 'Something went wrong. Check your network and try again.',
  noRestaurants: 'No Restaurants',
  clearCartText:  'By leaving this restaurant page, the items you`ve added to cart will be cleared',
  okText: 'OK'
}
