import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryAllOrders, GetOrderDetailsById } from "../../apollo/queries";
import { CancelOrder,ChangePaymentToCODForOrder, AddItemsForReorder  } from "../../apollo/mutations";
import { useMutation, gql, useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import CheckOrderStatus from '../../utils/CheckOrderStatus'
import CheckPaymentStatus from '../../utils/CheckPaymentStatus'
import CheckRefundStatus from '../../utils/CheckRefundStatus'
// import OrderDetails from './OrderDetails';
import UseCookie from '../../utils/cookies/useCookie';
import Colors from '../../utils/colors/Colors';
import { ThemeContext } from '../../context/Theme';
import { FaChevronRight } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import moment from 'moment';
import { RiBarChart2Fill } from "react-icons/ri";
import { RiDiscountPercentFill } from "react-icons/ri";
import { FaListAlt } from "react-icons/fa";
import { MdDeliveryDining } from "react-icons/md";
import { RiShoppingBag4Fill } from "react-icons/ri";
import { FaLocationDot } from "react-icons/fa6";
import { useLocation } from 'react-router-dom';
import CheckPaymentMethod from '../../utils/CheckPaymentMethod';
import { toast } from 'react-toastify';
import axios from 'axios';


const getAllOrders = gql`
  ${QueryAllOrders}
`;

const getOrderDetailsByOrderId = gql`
  ${GetOrderDetailsById}
`;

const CANCELORDER = gql`
  ${CancelOrder}
`;

const RequestToReorder = gql`
  ${AddItemsForReorder}
`;


const UPDATEPAYMENTMETHOD = gql`
  ${ChangePaymentToCODForOrder}
`;

export default function OrdersHistory(props) {
  const client = useApolloClient();
  const scrollDivRef = useRef(null);
  const { systemThemeMode,appColorTheme, setAppColorTheme} = useContext(ThemeContext)
  const colors = Colors[appColorTheme=='systemDefault'?systemThemeMode:appColorTheme];   
  const { t } = useTranslation()
  var [locationInfo,setLocationInfo] = useState()
  var [isLoading, setIsLoading] = useState(false);
  var [pageNumber,setPageNumber] = useState(0);
  var [ordersHistory, setOrdersHistory] = useState([]);
  var [orderDetails, setOrderDetails] = useState(false);
  var [orderDetailsData, setOrderDetailsData] = useState(false);
  var [selectedOrder, setSelectedOrder] = useState();
  const [loadingMore, setLoadingMore] = useState(false);
  const { setCustomCookie, getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();
  const {pathname} = useLocation();
  const [cancelOrder] = useMutation(CANCELORDER, { onCompletedCancelOrder, onErrorCancelOrder });
  var [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [UpdatePaymentMethod] = useMutation(UPDATEPAYMENTMETHOD);
  const [requestToReorder] = useMutation(RequestToReorder);


  async function onCompletedCancelOrder(data) {
  }

  function onErrorCancelOrder(errors) {
  }

  // Refs to hold the latest values of pageNumber and isLoadingMore
  const pageNumberRef = useRef(pageNumber);
  const isLoadingMoreRef = useRef(loadingMore);

  // Update the refs whenever the state changes
  useEffect(() => {
    pageNumberRef.current = pageNumber;
  }, [pageNumber]);

  useEffect(() => {
    isLoadingMoreRef.current = loadingMore;
  }, [loadingMore]);

  // const { data:ordersHistoryData, loading:ordersDataLoading, error, fetchMore } = useQuery(getAllOrders, {
  //   variables: {  
  //       "limit": 10 ,
  //       "skip": 10 * (pageNumber-1)
  //   }
  // })

  const [fetchMore, { data:ordersHistoryData, loading:ordersDataLoading, error }] = useLazyQuery(getAllOrders, {
    fetchPolicy: 'network-only',
    onCompleted: (fetchMoreResult) => {
      if(fetchMoreResult?.queryAllOrders?.orders){
        ordersHistory = ordersHistory.concat(fetchMoreResult.queryAllOrders?.orders)
        setOrdersHistory(ordersHistory);
        pageNumber = pageNumber + 1
        setPageNumber(pageNumber);
        }
        //console.log("🚀 ~ OrdersHistory ~ pageNumber:", pageNumber)
      isLoading = false
      setIsLoading(isLoading)
      setLoadingMore(false);
    },
  });

  const [getMoreOrderDetails, { data, loading, error: orderDetailsError }] = useLazyQuery(getOrderDetailsByOrderId, {
    fetchPolicy: 'network-only',
    onCompleted: (fetchMoreResult) => {
      if(fetchMoreResult?.getOrderDetailsById?.items){
        orderDetailsData = fetchMoreResult
        //console.log("🚀 ~ fetchOrderDetails ~ fetchMoreResult:", fetchMoreResult)
        setOrderDetailsData(orderDetailsData)
        console.log("🚀 ~ OrdersHistory ~ orderDetailsData:", orderDetailsData)
        selectedPaymentMethod = orderDetailsData?.getOrderDetailsById?.paymentMethod == 0 ? 'cod':'online'
        setSelectedPaymentMethod(selectedPaymentMethod)
        OpenOrderDetailsModal(true)
        isLoading = false
        setIsLoading(isLoading)
      }
      isLoading = false
      setIsLoading(isLoading)
    },
  });

  // const { data, loading, error:orderDetailsError, fetchMore:getMoreOrderDetails } = useQuery(getOrderDetailsByOrderId,{ 
  //   variables:{
  //   "orderId": ""
  // }})

  useEffect(() => {
  }, [pathname]);
  
  useEffect(() => {
    if(props?.isChecked){
      initialDataload()
    }
  },[props?.isChecked])

  const initialDataload = async()=>{
    window.scrollTo(0, 0);
    orderDetails = false;
    setOrderDetails(orderDetails);
    ordersHistory = []
    setOrdersHistory(ordersHistory);
    pageNumber = 0
    setPageNumber(pageNumber)
    setLoadingMore(true);
    isLoading = true
    setIsLoading(isLoading)
    try{
      fetchMore({
        variables: {
          "limit": 10 ,
          skip: 10 * pageNumber,
        },
      });
    }
    catch(error){
      isLoading = false
      setIsLoading(isLoading)
      setLoadingMore(false);
    }
  }

  React.useEffect(() => {
    if (!loading && data) {
    //   if(data?.getOrderDetailsById?.items){
    //     sortedDisplayItems = [...data?.getOrderDetailsById?.items]?.sort((a, b) => a?.sortOrder - b?.sortOrder);
    //     setSortedDisplayItems(sortedDisplayItems)
    //   }
    }
  }, [data, loading]);

  useEffect(() => {
    const divElement = scrollDivRef.current;
    if (divElement) {
      divElement.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (divElement) {
        divElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loadingMore]);

  const loadMoreItems = () => {
    //console.log("🚀 ~ loadMoreItems ~ ordersHistoryData?.queryAllOrders?.totalCount <= (10 * (pageNumber+1):", ordersHistoryData?.queryAllOrders?.totalCount , (10 * (pageNumberRef?.current)))
    if (isLoadingMoreRef?.current) return;
    if(ordersHistoryData?.queryAllOrders?.totalCount <= (10 * (pageNumberRef?.current))) return;
    try{
      fetchMore({
        variables: {
          "limit": 10 ,
          skip: 10 * pageNumberRef?.current,
        },
      });
    }
    catch(error){
    }
  };

  // const handleScroll1 = () => {
  //   //console.log(window.innerHeight, window.scrollY , document.documentElement.scrollHeight , window.innerHeight * 0.2)
  //   if (window.innerHeight + window.scrollY >= (document.documentElement.scrollHeight - (window.innerHeight * 0.5)) && !loadingMore) {
  //     loadMoreItems()
  //   }
  // };

  const handleScroll = () => {
    const divElement = scrollDivRef.current;
    if (
      divElement &&
      divElement.scrollTop + divElement.clientHeight >= divElement.scrollHeight - divElement.clientHeight * 0.2 &&
      !loadingMore
    ) {
        loadMoreItems();
    }
  };


//   React.useEffect(() => {
//     if (!ordersDataLoading && ordersHistoryData) {
//       if(ordersHistoryData?.queryAllOrders?.orders){
//         ordersHistory = ordersHistory.concat(ordersHistoryData.queryAllOrders?.orders)
//         setOrdersHistory(ordersHistory);
//       }
//           isLoading = false
//           setIsLoading(isLoading)
//     }
// }, [ordersHistoryData, ordersDataLoading]);

  const fetchOrderDetails = (OrderId) => {
    isLoading = true
    setIsLoading(isLoading)
    getMoreOrderDetails({
      variables: {
        "orderId": OrderId
      },
    });
  }

  const OrdersHistoryCards = ({item}) => {

    return  <div className={`hover:border-appThemeColorDark border-appThemeColorAboveLight border-2 shadow relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor}   rounded cursor-pointer`} 
    style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}
    onClick={()=>{fetchOrderDetails(item?._id)}}>

    <div className='w-full mx-2'>
      <div className="flex justify-between "> {/* Add sm:mb-0 to make sure it aligns correctly on small screens */}
        <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Ordered " + item?.itemsCount + (item?.itemsCount>1?' items':' item') +' - #'+ item?._id?.slice(-5)}</span>
        <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-appThemeColorVeryDark`}>₹{item?.orderAmount}</span>
      </div>
      <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md mr-1 text-${colors?.heading}`} style={{color:(item?.orderStatus)==7?colors?.success:(item?.orderStatus)>7?colors?.fail:colors?.pending}}>{CheckOrderStatus(item?.orderStatus)}</span>
      <div className="text-left "> {/* Add sm:mb-0 to make sure it aligns correctly on small screens */}
        <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Delivered on {item?.deliveryDate.split(",")[0]} between {item?.deliveryDate.split(",")[1]}</span>
      </div>
        <FaChevronRight className={`absolute  bottom-1 right-1 text-${colors?.heading}  text-xs sm:text-xs md:text-sm lg:text-sm xl:text-sm`} />
    </div>
  </div>
  }

  const OpenOrderDetailsModal = (value)=>{
    if(!value){initialDataload()}
    orderDetails = value
    setOrderDetails(orderDetails)
  }

  const formatOrderDate = (utcDate) => {
    const localDate = moment.utc(utcDate).local();
  
    // Get day of the month with ordinal suffix (e.g., 1st, 2nd, 3rd, ...)
    const dayOfMonth = localDate.format('Do');
  
    // Format date as '30th Apr 2024'
    const formattedDate = localDate.format('Do MMM YYYY');
  
    // Format time as '03:00 pm'
    const formattedTime = localDate.format('hh:mm a');
  
    // Combine formatted date and time
    const formattedDateTime = `${formattedDate}, ${formattedTime}`;
  
    //console.log("🚀 ~ formatOrderDate ~ formattedDateTime:", formattedDateTime)
    return formattedDateTime;
  };

  const priceDetailAsObject = (prices) => {
    let data =  prices?.reduce((priceDetailOfAllCustomerTypes, { customerType, sellingPrice })=>{priceDetailOfAllCustomerTypes[customerType] = sellingPrice;return priceDetailOfAllCustomerTypes},{})
    return data
  }

  const OrderItem = ({item,index}) => {
    //console.log("🚀 ~ OrderItem ~ item:", item)
      let loginInfo = getCustomCookie('loginInfo')
      let customerType = loginInfo?.customerType!=undefined?loginInfo?.customerType:0

      // Use useMemo to memoize the result of the expensive calculation
      const priceDetailOfAllCustomerTypes = useMemo(() => priceDetailAsObject(item?.prices), [item]);
      //console.log("🚀 ~ OrderItem ~ priceDetailOfAllCustomerTypes:", priceDetailOfAllCustomerTypes)

  return <div key={`_${index}OrderSummary`} className="flex justify-between">
    <p className={`text-${colors?.heading} text-sm`}>{item.quantity} x {item.name} (₹{priceDetailOfAllCustomerTypes[customerType]})</p>
    <p className={`text-${colors?.heading} text-sm`}>
    <span className="line-through">₹{item.quantity * item.mrp}</span> ₹{item.quantity * priceDetailOfAllCustomerTypes[customerType]}
    </p>
  </div>
}

  const cancelOrderClick = async()=>{
    let requestBody = {
      orderId:orderDetailsData?.getOrderDetailsById?._id
    }
    isLoading = true
    setIsLoading(isLoading)
    try{
      let data = await cancelOrder({ variables:requestBody})
      console.log("🚀 ~ cancelOrderClick ~ data:", data)
      if(data?.data?.cancelOrder){ 
        toast.success(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} Order Cancelled`)
        // fetchOrderDetails(orderDetailsData?.getOrderDetailsById?._id)
        fetchOrderDetails(orderDetailsData?.getOrderDetailsById?._id)
      }
      else{
        toast.error(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} Order Failed to Cancel`)
        }
    }catch(e){
      isLoading = false
      setIsLoading(isLoading)
      toast.error(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} Order Failed to Cancel`)
    }
  }


  const paymentTryAgain = async ( )=>{
    const params = new URLSearchParams({
        orderId: orderDetailsData?.getOrderDetailsById?._id,
        amount: orderDetailsData?.getOrderDetailsById?.billDetails?.grandTotal,
      });
      await setCustomCookie('checkOrderDetailsStatus', JSON.stringify({orderId: orderDetailsData?.getOrderDetailsById?._id}))
      window.location.href  = `${process.env.REACT_APP_SERVER_URL}/phonepe/payment?${params.toString()}`
  }


  const changePaymentMethodChange = async(event) => {
    selectedPaymentMethod = event.target.value
    setSelectedPaymentMethod(selectedPaymentMethod)
  }

  const updatePlaceOrder = async() => {
    try{
      isLoading = true
      setIsLoading(isLoading)
        let requestBody = {orderId:orderDetailsData?.getOrderDetailsById?._id,}
        let data = await UpdatePaymentMethod({ variables:requestBody})
        console.log("🚀 ~ handlePaymentMethodChange ~ data:", data)
        toast.success(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} order successfully updated to cash on delivery`)
        fetchOrderDetails(orderDetailsData?.getOrderDetailsById?._id)
    }
    catch(error){
      isLoading = false
      setIsLoading(isLoading)
      toast.error(` #${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)} order failed to update cash on delivery`)
    }
  };

  const confirmReOrder = async ( )=>{
    let response = {}
    try{
      response = await requestToReorder({ variables:{orderId:data?.getOrderDetailsById?._id}})
      props?.openCartDrawer()
    }
    catch(error){
      console.log("🚀 ~ confirmReOrder ~ error:", error?.message)
      toast.error(error?.message)
    }
  }

  const downloadPDF = async () => {
    const requestBody = {
      invoiceId: orderDetailsData?.getOrderDetailsById?.invoiceId || "KA24/06-IN00001"
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/invoice/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // const url = window.URL.createObjectURL(new Blob([response.data]));

      //download pdf
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'invoice.pdf'); // or extract the name from response headers if available
      document.body.appendChild(link);
      link.click();

      //open pdf in new browser tab
      // window.open(url, '_blank');
      // window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
    }
  }


  return (
        <>
          <div ref={scrollDivRef} className='mt-2p pb-2 overflow-y-scroll hide-scroll-bar max-h-[90vh]'>
          {isLoading ?
            <div className='flex justify-center'><div className={`loader ease-linear rounded-full border-2 border-t-8 border-gray-200 h-10 w-10`}></div></div>:
              !orderDetails?(ordersHistory?.length !=0?<div className='overflow-y-auto'>
              {
                ordersHistory.map((item,index) => (
                  <div key={`_${index}ordersHistory`} className=''><OrdersHistoryCards index={index} item={item} /></div>
                ))
              }
              </div>:
              <div className="flex h-96 items-center justify-center">
                <p className="text-center">No Orders</p>
              </div>
              ):
              <>  
              <div>
                <div className='flex align-center items-center justify-between'>
                  <div className={`flex align-center text-md sm:text-md md:text-md lg:text-xl xl:text-xl items-center gap-2 text-${colors?.heading} font-bold`}>
                    <FaArrowLeftLong className={`cursor-pointer text-lg sm:text-lg md:text-lg lg:text-2xl xl:text-2xl my-2 text-${colors?.heading}`} onClick={()=>OpenOrderDetailsModal(false)} />
                    <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>More Details</div>
                  </div>
                  {orderDetailsData?.getOrderDetailsById?.invoiceId && <button className="bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-1p" onClick={downloadPDF}>
                      <div className='flex flex-wrap items-center justify-center w-full' >
                        <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">Download Invoice</span>
                      </div>
                    </button>}
                </div>
                <div className={` border-appThemeColorAboveLight border-2 shadow relative flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded`} 
                    style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}}>

                    <div className='w-full mx-2'>
                      <div className="flex justify-between "> 
                        <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{"Ordered " +  orderDetailsData?.getOrderDetailsById?.items?.length + (orderDetailsData?.getOrderDetailsById?.items?.length>1?' items':' item') +' ('+ CheckOrderStatus(orderDetailsData?.getOrderDetailsById?.orderState?.status)+")"}</span>
                        {/* <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-appThemeColorVeryDark`}>₹{item?.orderAmount}</span> */}
                        <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md mr-1 font-bold text-appThemeColorVeryDark`}>{`#${orderDetailsData?.getOrderDetailsById?._id?.slice(-5)}`}</span>
                      </div>
                      <div className="text-left "> 
                        <div className={`text-sm my-1 sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Amount : {"₹" + orderDetailsData?.getOrderDetailsById?.orderAmount}</div>
                        <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Paid Amount : {"₹" + orderDetailsData?.getOrderDetailsById?.paidAmount}</div>
                        <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Method : {CheckPaymentMethod(orderDetailsData?.getOrderDetailsById?.paymentMethod)}</div>
                        <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Payment Status : { CheckPaymentStatus(orderDetailsData?.getOrderDetailsById?.paymentStatus)}</div>
                        {orderDetailsData?.getOrderDetailsById?.refundStatus!=0 && <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Refund Status : { CheckRefundStatus(orderDetailsData?.getOrderDetailsById?.refundStatus)}</div>}
                        <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Order Date : {formatOrderDate(orderDetailsData?.getOrderDetailsById?.orderDate)}</div>
                        <div className={`my-1 text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>Delivery Date : {orderDetailsData?.getOrderDetailsById?.deliveryDate}</div>
                      </div>
                    </div>
                  </div>
                  {/* Offers Applied */}
                  {(orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.applied || orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.applied || orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.applied ) && <>
                        <div className="flex flex-col gap-1 mb-3"> 
                          <span className={`text-left text-sm md:text-md font-bold text-${colors?.heading}`}>{t('OffersApplied')}</span>
                          {orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-2 relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                            <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.message}</span>
                          </div>}
                          {orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-2 relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                            <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.message}</span>
                          </div>}
                          {orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.applied && <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-2 relative flex p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left`} >
                            <span className={`text-left text-xs md:text-sm font-normal text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.message}</span>
                          </div>}
                        </div>
                      </>}
                  {orderDetailsData?.getOrderDetailsById?.deliveryAddress?.deliveryAddress && 
                    <>
                      <div className="text-left "> 
                            <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>Address</span>
                      </div>
                      <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`relative gap-2 flex flex-row items-center mb-3 p-2 border-appThemeColorAboveLight border-2 shadow bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded text-left`} >
                          <FaLocationDot className="text-green-600 text-lg sm:text-xl md:text-xl lg:text-xxl xl:text-xxl" />
                          <span className={`text-sm sm:text-sm md:text-sm lg:text-sm xl:text-sm text-${colors?.heading}`}>{orderDetailsData?.getOrderDetailsById?.deliveryAddress?.deliveryAddress}</span>
                      </div>
                    </>
                  }
                  {orderDetailsData?.getOrderDetailsById?.paymentStatus === 0 && orderDetailsData?.getOrderDetailsById?.orderState?.status < 7 && orderDetailsData?.getOrderDetailsById?.paymentMethod !== 0 && <>
                    <div className="text-left "> 
                          <span className={`text-sm sm:text-sm md:text-md lg:text-md xl:text-md font-bold mr-1 text-${colors?.heading}`}>{t('ChnagePaymentMethod')}</span>
                    </div>
                    <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`relative border-appThemeColorAboveLight border-2 shadow flex mb-3 p-2 bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded cursor-pointer text-left`} >
                      <div className="flex flex-col space-y-4">
                        <label className="flex items-center">
                          <input
                            type="radio"
                            value="cod"
                            checked={selectedPaymentMethod == 'cod'}
                            onChange={changePaymentMethodChange}
                            className="form-radio cursor-pointer h-4 w-4 text-green-600 checked:bg-green-600 focus:bg-green-600 focus:ring-0 transition duration-100 ease-in-out"
                            />
                          <span className="ml-2 text-gray-700">{t('cod')}</span>
                        </label>
                        <label className="flex items-center">
                          <input
                            type="radio"
                            value="online"
                            onChange={changePaymentMethodChange}
                            checked={selectedPaymentMethod == 'online'}
                            className="form-radio cursor-pointer h-4 w-4 text-green-600 checked:bg-green-600 focus:bg-green-600 focus:ring-0 transition duration-100 ease-in-out"
                          />
                          <span className="ml-2 text-gray-700">{t('online')}</span>
                        </label>
                      </div>          
                    </div>
                  </>}
                  <div style={{backgroundColor:Colors.colorTheme[colors?.backgroundColor]}} className={`border-appThemeColorAboveLight border-appThemeColorAboveLight border-2 shadow bg-${colors?.backgroundColor} dark:bg-${colors?.backgroundColor} rounded-lg shadow-md p-4 my-2 `}>
                    {orderDetailsData?.getOrderDetailsById?.items?.map((item, index) => (
                        <OrderItem item={item} index={index} />
                    ))}
                    <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                    {orderDetailsData?.getOrderDetailsById?.billDetails && (
                    <>
                        <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiBarChart2Fill className={`text-${colors?.cartIconBadge}`} name="graph-bar" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>{t('MRP')}</p>
                        </div>
                        <p className={`text-${colors?.heading} text-sm`}>₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp}</p>
                        </div>
                        <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                            {t('Discount')} <span className={`text-${colors?.cartIconBadge}`}>({((parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp - orderDetailsData?.getOrderDetailsById?.billDetails?.itemsTotal) / parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp)) * 100).toFixed(1)}%)</span>
                            </p>
                        </div>
                        <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.totalSaving)}</p>
                        </div>
                        <div className="flex items-center justify-between my-1">
                          <div className="flex items-center">
                              <FaListAlt className={`text-${colors?.cartIconBadge}`} name="list-alt" size={12} />
                              <p className={`text-${colors?.heading} text-sm ml-2`}>{t('ItemsTotalPrice')}</p>
                          </div>
                          <p className={`text-${colors?.heading} text-sm`}> ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsTotal}</p>
                        </div>
                        {orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.applied && <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>
                                {t('NestCash')} 
                            </p>
                            </div>
                            <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.nestInfo?.amount)}</p>
                        </div>}
                        {orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.applied && <div className="flex items-center justify-between my-1">
                            <div className="flex items-center">
                                <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                                <p className={`text-${colors?.heading} text-sm ml-2`}>
                                    {t('Wallet')} 
                                </p>
                            </div>
                            <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.walletInfo?.amount)}</p>
                        </div>}
                        {orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.applied && <div className="flex items-center justify-between my-1">
                            <div className="flex items-center">
                                <RiDiscountPercentFill className={`text-${colors?.cartIconBadge}`} name="discount" size={12} />
                                <p className={`text-${colors?.heading} text-sm ml-2`}>
                                    {t('Coupon')} 
                                </p>
                            </div>
                            <p className={`text-${colors?.cartIconBadge} text-sm`}>- ₹{parseInt(orderDetailsData?.getOrderDetailsById?.billDetails?.couponInfo?.amount)}</p>
                        </div>}
                        <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <MdDeliveryDining className={`text-${colors?.cartIconBadge}`} name="delivery-dining" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>{t('DeliveryCharges')}</p>
                        </div>
                        <p className={`text-${colors?.heading} text-sm`}>+ ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.deliveryCharge}</p>
                        </div>
                        <div className="flex items-center justify-between my-1">
                        <div className="flex items-center">
                            <RiShoppingBag4Fill className={`text-${colors?.cartIconBadge}`} name="shopping-bag" size={12} />
                            <p className={`text-${colors?.heading} text-sm ml-2`}>{t('HandlingCharge')}</p>
                        </div>
                        <p className={`text-${colors?.heading} text-sm`}>+ ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.handlingCharge}</p>
                        </div>
                        <hr className={`border-t border-${colors?.subChipBackground} my-2`} />
                    </>
                    )}
                    <div className="flex justify-between my-1">
                    <p className={`text-${colors?.heading} text-sm`}>{t('GrandTotal')} ({orderDetailsData?.getOrderDetailsById?.items?.length})</p>
                    <p className={`text-${colors?.heading} text-sm`}>
                        <span className="line-through">₹{orderDetailsData?.getOrderDetailsById?.billDetails?.itemsMrp}</span><span className='font-bold'> ₹{orderDetailsData?.getOrderDetailsById?.billDetails?.grandTotal }</span>
                    </p>
                    </div>
                </div>
                {/* <>
                  {orderDetailsData?.getOrderDetailsById?.paymentStatus === 0 && orderDetailsData?.getOrderDetailsById?.orderState?.status < 7 && orderDetailsData?.getOrderDetailsById?.paymentMethod !== 0 && (
                    <div className="mt-2 mb-0 flex flex-row items-center justify-center">
                      <span className="text-xs">Are you failed to pay online?</span>
                    </div>
                  )}
                  {orderDetailsData?.getOrderDetailsById?.paymentStatus === 0 && orderDetailsData?.getOrderDetailsById?.orderState?.status < 7 && orderDetailsData?.getOrderDetailsById?.paymentMethod !== 0 && (
                    <div className="mt-0 mb-2 flex flex-row items-center justify-center">
                      <button className="text-xs font-bold text-appThemeColorVeryDark underline" onClick={paymentTryAgain}>
                        Try Pay Now
                      </button>
                      <span className="text-xs mx-1">or</span>
                      <button className="text-xs font-bold text-appThemeColorVeryDark underline" onClick={updatePlaceOrder}>
                        Change Payment Method
                      </button>
                    </div>
                  )}
                </> */}
                <div className="gap-2 flex flex-col items-center justify-center">
                  <button className="w-full bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-2p" onClick={confirmReOrder}>
                    <div className='flex flex-wrap items-center justify-center w-full' >
                      <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">{t('reOrder')}</span>
                    </div>
                  </button>
                  {orderDetailsData?.getOrderDetailsById?.orderState?.status<7 && 
                  <>
                    <div className="gap-5 w-full flex flex-row items-center justify-center">
                      <button className="w-full bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-2p" onClick={cancelOrderClick}>
                        <div className='flex flex-wrap items-center justify-center w-full' >
                          <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">{t('CancelOrder')}</span>
                        </div>
                      </button>
                      {orderDetailsData?.getOrderDetailsById?.paymentStatus === 0 && orderDetailsData?.getOrderDetailsById?.orderState?.status < 7 && orderDetailsData?.getOrderDetailsById?.paymentMethod !== 0 && 
                      <button className="w-full bg-appThemeColorVeryDark text-white flex justify-between items-center rounded p-2p" onClick={selectedPaymentMethod == 'online'?paymentTryAgain:updatePlaceOrder}>
                        <div className='flex flex-wrap items-center justify-center w-full' >
                          <span className="text-sm sm:text-sm md:text-md lg:text-md xl:text-md mx-1">{selectedPaymentMethod == 'online'?t('RetryPayment'):t('UpdateOrder')}</span>
                        </div>
                      </button>}
                    </div>
                  </>
                  }
                </div>
              </div>
              </>
          }
              {/* {orderDetails && <OrderDetails orderDetails={orderDetails} orderId={selectedOrder?._id} navigation={navigation} OpenOrderDetailsModal={OpenOrderDetailsModal} />} */}
          </div>
        </>
  )
}