import React, {
  useRef,
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChartBarIcon, BellIcon } from "@heroicons/react/24/outline";
import { AiOutlineSearch } from "react-icons/ai";
import { IoCartOutline, IoCloseCircleOutline, IoClose } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import SignIn from "../SignIn/SignIn";
import Cart from "../Cart/Cart";
import UseCookie from "../../utils/cookies/useCookie";
import Lottie from "lottie-react";
import { LoginInfoContext } from "../../context/loginInfo";
import { Router, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../ui-components/Loader/Loader";
import { FaRegCircleUser } from "react-icons/fa6";
import { TbSquareLetterN } from "react-icons/tb";
import { logOut } from "../../apollo/mutations";
import { CartContext } from "../../context/Cart";
import { useMutation, gql, useQuery } from "@apollo/client";
import { LocationContext } from "../../context/Location";
import Profile from "../Menu/Profile";
import Colors from "../../utils/colors/Colors";
import { ThemeContext } from "../../context/Theme";
import { useTranslation } from "react-i18next";
import OrdersHistory from "../Menu/OrdersHistory";
import { FaLocationDot } from "react-icons/fa6";
import { MdKeyboardArrowDown } from "react-icons/md";
import SelectDeliveryAddress from "../../ui-components/Address/SelectDeliveryAddress";
import PremuimBanner from "../../ui-components/PremuimBanner/PremuimBanner";
import { FaCrown } from "react-icons/fa";
import Wallet from "../Menu/Wallet";
import ReferralHistory from "../Menu/ReferralHistory.js";
import NestCash from "../Menu/NestCash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const LOGOUT = gql`
  ${logOut}
`;

export default function NavBar(props) {
  const { systemThemeMode, appColorTheme, setAppColorTheme } =
    useContext(ThemeContext);
  const colors =
    Colors[appColorTheme == "systemDefault" ? systemThemeMode : appColorTheme];
  const modalRef = useRef(null);
  const { t } = useTranslation();
  const navigateScreenTo = useNavigate();
  const { cartItems, setCartItems } = useContext(CartContext);
  const { location, setLocation } = useContext(LocationContext);
  const [showModal, setShowModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [drawerName, setDrawerName] = useState([]);
  const [modalName, setModalName] = useState([]);
  const [navBarStyles, setNavBarStyles] = useState([]);
  var [selectAddress, setSelectAddress] = useState(false);
  var [loading, setLoading] = useState(false);
  var [premuimBanner, setPremuimBanner] = useState(false);
  // var { loginInfo, setLoginInfo } = useContext(LoginInfoContext);
  var [loginInfo, setLoginInfo] = useState({});
  const { getCustomCookie, removeCustomCookie, clearAllCookies } = UseCookie();
  const [LogOut] = useMutation(LOGOUT, { onCompleted, onError });

  async function onCompleted({ login }) {
    // //console.log("🚀 ~ Profile onCompleted ~ login:", login)
  }

  async function onError(errors) {
    // //console.log("🚀 ~ Profile onError ~ errors: Profile", errors)
    await clearAllCookies();
    let cartItemsValue = {};
    setCartItems(cartItemsValue);
    setLocation(null);
  }

  const loggedInMenu = [
    {
      name: "Your Profile",
      navigateTo: () => {
        openModalDrawer("SignIn");
      },
      current: false,
    },
    // { name: 'Orders History', navigateTo: ()=>{}, current: true },
    // { name: 'Appearance', navigateTo: ()=>{}, current: false },
    // { name: "FAQ\'s", navigateTo: ()=>{}, current: false },
    {
      name: "Order History",
      navigateTo: () => {
        openModalDrawer("OrderHistory");
      },
      current: false,
    },
    {
      name: "Wallet",
      navigateTo: () => {
        openModalDrawer("Wallet");
      },
      current: false,
    },
    {
      name: "Nest Cash",
      navigateTo: () => {
        openModalDrawer("NestCash");
      },
      current: false,
    },
    {
      name: "Manage Referrals",
      navigateTo: () => {
        openModalDrawer("Referral");
      },
      current: false,
    },
    {
      name: "Appearance",
      navigateTo: () => {
        openModal("Appearance");
      },
      current: false,
    },
    {
      name: "Privacy Policy",
      navigateTo: () => {
        navigateScreenTo("/PrivacyPolicy");
      },
      current: false,
    },
    {
      name: "Terms and Conditions",
      navigateTo: () => {
        navigateScreenTo("/TermsAndConditions");
      },
      current: false,
    },
    {
      name: "Refund and Return Policy",
      navigateTo: () => {
        navigateScreenTo("/RefundAndReturnPolicy");
      },
      current: false,
    },
    {
      name: "Shipping and Delivery Policy",
      navigateTo: () => {
        navigateScreenTo("/ShippingPolicy");
      },
      current: false,
    },
    {
      name: "Contact Us",
      navigateTo: () => {
        navigateScreenTo("/ContactUs");
      },
      current: false,
    },
    {
      name: "Sign out",
      navigateTo: () => {
        logOut();
      },
      current: false,
    },
  ];

  const loggedOut = [
    // { name: 'SignIn', navigateTo: ()=>{openModal('SignIn')}, current: false },
    // { name: 'Appearance', navigateTo: ()=>{openModal('Appearance')}, current: false },
    // { name: "FAQ\'s", navigateTo: ()=>{}, current: false },
    // { name: "Privacy Policy", navigateTo: ()=>{navigateScreenTo("/PrivacyPolicy")}, current: false },
    // { name: "Terms and Conditions", navigateTo: ()=>{navigateScreenTo("/TermsAndConditions")}, current: false },
    // { name: "Contact Us", navigateTo: ()=>{navigateScreenTo("/contactus")}, current: false },
  ];

  const handleSearch = (e) => {
    if (e?.target?.value?.trim()) {
      navigateScreenTo("/SearchProducts", { state: e.target.value.trim() });
    } else {
      navigateScreenTo("/");
      return false;
    }
    // setActiveSearch(words.filter(w => w.includes(e.target.value)).slice(0,8))
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (props?.searchLocation) {
      selectAnotherLocation(true);
    }
  }, [props?.searchLocation]);

  useEffect(() => {
    // handleSearch("");
    verifyLoginInfo();
    loader();
  }, []);

  useEffect(() => {
    if (isChecked || selectAddress) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up the class on component unmount
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isChecked, selectAddress]);

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  const openModal = (modalName) => {
    setModalName(modalName);
    setShowModal(true);
    document.addEventListener("mousedown", handleClickOutside);
  };

  const closeModal = () => {
    setShowModal(false);
    document.removeEventListener("mousedown", handleClickOutside);
  };
  const handleClickOutsideDrawer = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModalDrawer();
    }
  };

  const openModalDrawer = (drawerName) => {
    setDrawerName(drawerName);
    setIsChecked(true);
    document.addEventListener("mousedown", handleClickOutsideDrawer);
  };

  const closeModalDrawer = () => {
    try {
      verifyLoginInfo();
      setIsChecked(false);
      document.removeEventListener("mousedown", handleClickOutsideDrawer);
    } catch (error) {
      console.log("🚀 ~ closeModalDrawer ~ error:", error);
    }
  };

  const verifyLoginInfo = async () => {
    let loginInfo = await getCustomCookie("loginInfo");
    console.log("🚀 ~ verifyLoginInfo ~ loginInfo:", loginInfo);
    setLoginInfo(loginInfo);
  };

  const setLoginData = (data) => {
    let loginInfo = data;
    console.log("🚀 ~ setLoginData ~ loginInfo:", loginInfo);
    setLoginInfo(loginInfo);
  };

  const loggedIn = (data) => {
    try {
      console.log("🚀 ~ loggedIn ~ data:", data);
      setLoginData(data);
      closeModal();
      loader();
    } catch (error) {
      console.log("🚀 ~ loggedIn ~ error:", error);
    }
  };

  const loader = () => {
    loading = true;
    setLoading(loading);
    setTimeout(() => {
      loading = false;
      setLoading(loading);
    }, 500);
  };

  // const logOut=async ()=>{
  //   loader();
  //   await removeCustomCookie('loginInfo')
  //   loginInfo = null
  //   setLoginInfo(loginInfo)
  // }

  const logOut = async () => {
    loading = true;
    setLoading(loading);
    let loginInfo = await getCustomCookie("loginInfo");
    if (loginInfo && Object.keys(loginInfo)?.length != 0) {
      try {
        let response = await LogOut({ variables: {} });
        await clearAllCookies();
        let cartItemsValue = {};
        setCartItems(cartItemsValue);
        // setLocation({ ...location, label: null });
        loginInfo = {};
        setLoginInfo(loginInfo);
      } catch (e) {
        await clearAllCookies();
        let cartItemsValue = {};
        setCartItems(cartItemsValue);
        // setLocation({ ...location, label: null });
        loginInfo = {};
        setLoginInfo(loginInfo);
      }
    } else {
      await clearAllCookies();
    }
    loading = false;
    setLoading(loading);
  };

  const selectAnotherLocation = async (value) => {
    try {
      if (!value) {
        verifyLoginInfo();
      }
      selectAddress = value;
      setSelectAddress(selectAddress);
    } catch (error) {
      console.log("🚀 ~ selectAnotherLocation ~ error:", error);
    }
  };

  const openPremuimBanner = useCallback(async (value) => {
    premuimBanner = value;
    setPremuimBanner(premuimBanner);
  }, []);

  return (
    <>
      <div className="">
        <Disclosure
          as="nav"
          style={{ backgroundColor: Colors.colorTheme[colors?.navColor] }}
          className={
            loginInfo &&
            Object.keys(loginInfo)?.length != 0 &&
            loginInfo?.customerType == 1
              ? `z-40 bg-${colors?.navColor} dark:bg-${colors?.navColor} bg-gradient-to-r from-gold1 via-gold2 via-gold4 to-gold3 w-full border-b-1 fixed shadow-lg shadow-appThemeColorVeryLight`
              : `z-40 bg-${colors?.navColor} dark:bg-${colors?.navColor} w-full border-b-1 fixed shadow-lg shadow-appThemeColorVeryLight`
          }
        >
          {({ open }) => (
            <>
              <div className="flex flex-col mx-auto max-w-7xl px-1 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  <div
                    // className="hidden xs:hidden sm:hidden md:hidden lg:flex xl:flex xxl:flex flex-shrink-0 items-center mr-4 cursor-pointer"
                    className=" flex flex-shrink-0 items-center mr-4 cursor-pointer"
                    onClick={() => navigateScreenTo("/")}
                  >
                    <img
                      className="h-8 sm:h-8 md:h-8 lg:h-12 xl:h-12 xxl:h-12 w-auto"
                      src={
                        // process.env.REACT_APP_PLACEHOLDER_IMAGE
                        "https://cdn.nativenest.in/pro/logo/Native-Nest-Trans-Logo.png"
                      }
                      alt="Logo"
                    />
                    {/* <TbSquareLetterN className="h-8 w-8 m-1 relative rounded-full bg-green-500 text-white hover:text-white focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800" /> */}
                    {/* <text className="pl-5 text-appThemeColorVeryDark text-2xl sm:text-xl md:text-xxl lg:text-xxl xl:text-3xl font-bold">
                      NativeNest
                    </text> */}
                  </div>
                  <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                    {location ? (
                      <div
                        className=" cursor-pointer flex-col items-center justify-center w-72  sm:w-40 md:w-52 md:w-52  transition duration-200 transform active:scale-90"
                        onClick={() => selectAnotherLocation(true)}
                      >
                        <div className="flex flex-row justify-start items-center gap-1 w-52">
                          <FaLocationDot className="text-green-600 text-lg sm:text-sm md:text-sm lg:text-lg xl:text-lg" />
                          <span
                            className={`text-${colors?.heading} font-bold text-sm sm:text-sm md:text-sm lg:text-md xl:text-md`}
                          >
                            {location?.label
                              ? location?.label
                              : "Selected Location"}
                          </span>
                        </div>
                        <div className="flex flex-row justify-start items-center w-52 ">
                          <span
                            className={`text-${colors?.heading} text-xs sm:text-xs md:text-xs lg:text-xs xl:text-xs font-bold truncate w-52 pl-1 text-center`}
                          >
                            {location?.deliveryAddress}
                          </span>
                          <MdKeyboardArrowDown
                            className={` text-md sm:text-md md:text-lg  lg:text-xl  xl:text-xl  text-${colors?.heading} pt-0.5`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        className=" cursor-pointer flex-row items-center justify-center align-center w-72  sm:w-40 md:w-52 md:w-52  transition duration-200 transform active:scale-90"
                        onClick={() => selectAnotherLocation(true)}
                      >
                        <div className="flex flex-row place-items-center justify-center align-center justify-center items-center w-52 pt-2 gap-2">
                          <FaLocationDot className="text-green-600 text-lg sm:text-sm md:text-sm lg:text-lg xl:text-lg" />
                          <span
                            className={`text-${colors?.heading} text-sm sm:text-sm md:text-md lg:text-lg xl:text-lg`}
                          >
                            Select Location
                          </span>
                          <MdKeyboardArrowDown
                            className={` text-md sm:text-md md:text-lg  lg:text-xl  xl:text-xl  text-${colors?.heading} pt-0.5`}
                          />
                        </div>
                      </div>
                    )}
                    <div className="relative w-full hidden xs:hidden sm:hidden md:flex lg:flex xl:flex xxl:flex flex-col sm:ml-6 sm:block">
                      <div className="flex items-center flex-row w-full rounded bg-green-100">
                        <button className="p-3">
                          <AiOutlineSearch className="text-2xl" />
                        </button>
                        <input
                          type="search"
                          placeholder="Search for a grocery item"
                          className="w-full pr-2 border-none focus:border-none outline-none focus:outline-none ring-0 focus:ring-0 bg-green-100"
                          onChange={(e) => handleSearch(e)}
                        />
                      </div>

                      {/* {activeSearch.length > 0 && (
                        <div className="absolute top-20 p-4 bg-green-200 text-black w-full rounded-xl left-1/2 -translate-x-1/2 flex flex-col gap-2">
                          {activeSearch.map((s) => (
                            <span>{s}</span>
                          ))}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    {/* Profile dropdown */}
                    {loginInfo && Object.keys(loginInfo)?.length != 0 && (
                      <Menu as="div" className="relative ml-3">
                        <div className="flex flex-shrink-0 items-center">
                          <Menu.Button className="flex flex-row text-center justify-center items-center transition duration-200 transform active:scale-90">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>
                            {/* <img
                          className="h-8 w-8 m-1 relative rounded-full bg-green-500 text-black-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        /> */}
                            <FaRegCircleUser className="h-8 w-8 m-1 relative rounded-full bg-green-500 text-white hover:text-white focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800 transition-shadow duration-300 ease-in-out" />
                            <div className="relative hidden sm:flex flex-col text-center justify-center items-center">
                              {loginInfo &&
                                Object.keys(loginInfo)?.length != 0 &&
                                loginInfo?.customerType == 1 && (
                                  <FaCrown className=" absolute -top-3 text-lg sm:text-lg md:text-lg  lg:text-lg  xl:text-lg text-yellow-900" />
                                )}
                              <text
                                className={`hidden sm:block text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl cursor-pointer text-${colors?.heading}`}
                              >
                                {loginInfo ? loginInfo?.name : "Menu"}
                              </text>
                            </div>
                          </Menu.Button>
                          <IoMdArrowDropdown
                            className="hidden h-6 w-6 text-white-400 mt-1"
                            aria-hidden="true"
                          />
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {(loginInfo ? loggedInMenu : loggedOut).map(
                              (item, index) => (
                                <Menu.Item
                                  key={index + "Manu-Items"}
                                  style={{ cursor: "pointer" }}
                                >
                                  {({ active }) => (
                                    <div
                                      key={item.name}
                                      onClick={item.navigateTo}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                      aria-current={active ? "page" : undefined}
                                    >
                                      {item.name}
                                    </div>
                                  )}
                                </Menu.Item>
                              )
                            )}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                    {!(loginInfo && Object.keys(loginInfo)?.length != 0) && (
                      <div
                        className="flex flex-shrink-0 items-center"
                        onClick={() => openModal("SignIn")}
                      >
                        <FaRegCircleUser className="h-8 w-8 m-1 relative rounded-full bg-green-500 text-white hover:text-white focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800" />
                        <text
                          className={`hidden sm:block text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl cursor-pointer text-${colors?.heading}`}
                        >
                          SignIn
                        </text>
                      </div>
                    )}
                    <div className="m-1 border-l-2 border-gray-500 h-8"></div>

                    <input
                      type="checkbox"
                      id="drawer-toggle"
                      className="relative sr-only peer"
                      checked={isChecked}
                      onChange={() => {
                        openModalDrawer("cart");
                      }}
                    />
                    <label
                      htmlFor="drawer-toggle"
                      className="flex flex-shrink-0 items-center cursor-pointer"
                    >
                      <div className="relative rounded-full bg-green-500 mr-1 p-1 text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <IoCartOutline
                          className="h-6 w-6 text-white-400"
                          aria-hidden="true"
                        />
                        <div
                          style={{
                            backgroundColor:
                              Colors.colorTheme[colors?.navColor],
                            borderColor:
                              Colors.colorTheme[colors?.buttonBackground],
                          }}
                          className={` cursor-pointer rounded-full absolute text-center -top-2 right-0 w-4 sm:w-4 md:w-4 lg:w-5 xl:w-5 h-4 sm:h-4 md:h-4 lg:h-5 xl:h-5 shadow-lg shadow-appThemeColorVeryLight border-1 sm:border-1 md:border-2 lg:border-2 xl:border-2 border-${colors?.navColor} transition duration-200 transform hover:scale-110 flex items-center justify-center text-${colors?.heading}`}
                        >
                          <text
                            className={`text-xs sm:text-xs md:text-xs lg:text-sm xl:text-sm text-${colors?.heading}`}
                          >
                            {cartItems?.items?.length > 0
                              ? cartItems?.items?.length
                              : 0}
                            {/* {cartItems?.items?.length > 0
                              ? cartItems?.items?.reduce(
                                  (noTotalItems, item) =>
                                    noTotalItems + item?.quantity,
                                  0
                                )
                              : 0} */}
                          </text>
                        </div>
                      </div>
                      <text
                        className={`hidden sm:block text-black text-sm sm:text-sm md:text-md lg:text-lg xl:text-xl text-${colors?.heading}`}
                      >
                        Cart
                      </text>
                    </label>
                  </div>
                </div>
                <div className=" flex xs:flex sm:flex md:hidden lg:hidden xl:hidden xxl:hidden items-center flex-row w-full mb-2 rounded bg-green-100">
                  <button className="p-2">
                    <AiOutlineSearch className="text-md sm:text-md md:text-lg  lg:text-xl  xl:text-xl" />
                  </button>
                  <input
                    type="search"
                    placeholder="Search for grocery item"
                    className="w-full pr-2 text-sm sm:text-sm md:text-md lg:text-md xl:text-md border-none focus:border-none outline-none focus:outline-none ring-0 focus:ring-0 bg-green-100"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
              </div>
            </>
          )}
        </Disclosure>

        {showModal && (
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="false"
          >
            <div
              className="fixed inset-0 bg-modalBackGround transition-opacity"
              onClick={closeModal}
            ></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full  items-center justify-center p-4 text-center sm:items-center sm:p-0">
                <div
                  ref={modalRef}
                  style={{
                    backgroundColor: Colors.colorTheme[colors?.buttonText],
                  }}
                  className={`relative transform overflow-hidden rounded-lg bg-${colors?.buttonText} dark:bg-${colors?.buttonText} text-left shadow-xl transition-all sm:my-8  w-full sm:w-full sm:max-w-lg`}
                >
                  <IoClose
                    onClick={closeModal}
                    className={`absolute right-3 top-3 sm:right-3 sm:top-3 md:right-4 md:top-4 lg:right-5 lg:top-5 h-4 sm:h-4 md:h-4 lg:h-6 w-4 sm:w-4 md:w-4 lg:w-6  text-black-400 cursor-pointer text-${colors?.heading}`}
                    aria-hidden="true"
                  />
                  {modalName == "SignIn" ? (
                    <SignIn eventToVerifyAccount={loggedIn} setLoginData={(data)=>setLoginData(data)} />
                  ) : (
                    <>
                      {/* <div className='flex items-center justify-center text-center m-4p'>
                          <div className="flex items-center cursor-pointer max-w-md mx-auto text-center bg-white px-4 sm:px-8 py-5 text-center">
                            <label className="mr-2 text-md sm:text-md md:text-md lg:text-lg xxl:text-xl font-medium">Appearance</label>
                            <div className={`relative inline-block w-6 h-6 rounded-full transition-all ${isOn ? 'bg-blue-600' : 'bg-gray-200'}`} onClick={handleToggle} >
                              <span className={`absolute top-0 left-0 block w-2 h-2 rounded-full bg-white transition-transform ${isOn ? 'translate-x-full' : ''}`}></span>
                            </div> 
                            <button onClick={handleToggle} className={`relative inline-flex items-center h-4 sm:h-4 md:h-5 lg:h-6 rounded-full w-8 sm:w-8 md:w-10 lg:w-11  transition-colors focus:outline-none ${isOn ? 'bg-blue-500' : 'bg-gray-300'}`}>
                                <span className={`inline-block  h-2 sm:h-2 md:h-4 lg:h-4 w-2 sm:w-2 md:w-4 lg:w-4  transform bg-white rounded-full transition-transform ${isOn ? 'translate-x-5' : 'translate-x-1'}`}/>
                            </button>
                          </div>
                        </div> */}
                      <div className="flex flex-col justify-center m-4p mx-10p">
                        <h2
                          className={`my-2p font-semibold text-md sm:text-md md:text-lg lg:text-lg xl:text-xl text-${colors?.heading}`}
                        >
                          {t("ChooseTheme")}
                        </h2>
                        <div className="flex flex-col ">
                          <label className="flex my-1p items-center">
                            <input
                              type="radio"
                              name="theme"
                              value="light"
                              checked={appColorTheme === "light"}
                              onChange={() => {
                                setAppColorTheme("light");
                              }}
                              className="form-radio text-blue-600"
                            />
                            <span
                              className={`ml-2 text-sm sm:text-sm md:text-md lg:text-md xl:text-md text-${colors?.heading}`}
                            >
                              {t("light")}
                            </span>
                          </label>
                          <label className="flex my-1p items-center">
                            <input
                              type="radio"
                              name="theme"
                              value="dark"
                              checked={appColorTheme === "dark"}
                              onChange={() => {
                                setAppColorTheme("dark");
                              }}
                              className="form-radio text-blue-600"
                            />
                            <span
                              className={`ml-2  text-sm sm:text-sm md:text-md lg:text-md xl:text-md  text-${colors?.heading}`}
                            >
                              {t("dark")}
                            </span>
                          </label>
                          <label className="flex my-1p items-center">
                            <input
                              type="radio"
                              name="theme"
                              value="default"
                              checked={appColorTheme === "systemDefault"}
                              onChange={() => {
                                setAppColorTheme("systemDefault");
                              }}
                              className="form-radio text-blue-600"
                            />
                            <span
                              className={`ml-2  text-sm sm:text-sm md:text-md lg:text-md xl:text-md  text-${colors?.heading}`}
                            >
                              {t("systemDefault")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {selectAddress && (
          <SelectDeliveryAddress
            handleGetLocation={props?.handleGetLocation}
            currentLocation={location}
            requestComponent={"NavBar"}
            selectAddress={selectAddress}
            setLoginDetails={setLoginData}
            selectAnotherLocation={(value) => {
              selectAnotherLocation(value);
              let searchLocation = value;
              props?.setSearchLocation(searchLocation);
            }}
          />
        )}

        <div className="flex relative z-50 ">
          {
            <input
              type="checkbox"
              id="drawer-toggle"
              className="relative sr-only peer"
              checked={isChecked}
              onChange={() => {
                openModalDrawer("cart");
              }}
            />
          }
          {isChecked && (
            <div
              className="fixed inset-0 bg-modalBackGround transition-opacity"
              onClick={closeModalDrawer}
            ></div>
          )}
          <div
            style={{ backgroundColor: Colors.colorTheme[colors?.navColor] }}
            className={`bg-${colors?.navColor} dark:bg-${colors?.navColor} fixed top-0 right-0 z-20 w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3  h-full transition-all duration-500 transform translate-x-full bg-white shadow-lg peer-checked:translate-x-0`}
          >
            <IoClose
              onClick={closeModalDrawer}
              className={`absolute right-5 top-5 h-6 w-6 text-black-400 cursor-pointer text-${colors?.heading}`}
              aria-hidden="true"
            />
            <div className="px-6 py-4">
              <h2 className={`text-lg font-semibold text-${colors?.heading}`}>
                {drawerName == "cart"
                  ? "Cart"
                  : drawerName == "OrderHistory"
                  ? "Orders History"
                  :drawerName == "Wallet"
                  ? "Wallet"
                  :drawerName == "NestCash"
                  ? "Nest Cash"
                  : "Profile"}
              </h2>
              {drawerName == "cart" ? (
                <Cart
                  setLoginDetails={setLoginData}
                  openPremuimBanner={openPremuimBanner}
                  selectAnotherLocation={selectAnotherLocation}
                  openSigninModal={() => {
                    openModal("SignIn");
                    closeModalDrawer();
                  }}
                  closeCartDrawer={closeModalDrawer}
                  isChecked={isChecked}
                />
              ) : drawerName == "OrderHistory" ? (
                <OrdersHistory
                  closeCartDrawer={closeModalDrawer}
                  isChecked={isChecked}
                  openCartDrawer={()=>openModalDrawer('cart')}
                />
              ) : drawerName == "Wallet" ?(
                <Wallet 
                  closeCartDrawer={closeModalDrawer}
                  isChecked={isChecked}
                />
              ) : drawerName == "NestCash" ?(
                <NestCash 
                  closeCartDrawer={closeModalDrawer}
                  isChecked={isChecked}
                />
              ): drawerName == "Referral" ?(
                <ReferralHistory
                  closeCartDrawer={closeModalDrawer}
                  isChecked={isChecked}
                />
              )
              : (
                <Profile
                  closeCartDrawer={closeModalDrawer}
                  isChecked={isChecked}
                />
              )}
            </div>
          </div>
        </div>
        {premuimBanner && (
          <PremuimBanner
            Colors={Colors}
            colors={colors}
            banner={premuimBanner}
            openPremuimBanner={openPremuimBanner}
          />
        )}
        <Loader loading={loading} />
      </div>
    </>
  );
}
